import React from 'react';
import { useTranslation } from 'react-i18next';
import loadScript from '../Tools/loadScript';
import axios from 'axios';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
}

declare const window: any;
export type ApplePaySession = any;

interface ApplePayButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

  buttonstyle:string|undefined;
  type:string|undefined;
  locale:string|undefined;
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["apple-pay-button"]: ApplePayButtonProps;
    }
  }
}

const AppleCheckoutComponent: React.FC = () => {

  const { i18n } = useTranslation();
  const [appleActivated, setAppleActivated] = React.useState(false);

  // Snackbar
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
  });
  const { vertical, horizontal, open } = state;

  const showSnackbar = (msg:string) => () => {
    setState({ ...state, open: true, message: msg });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const validateMerchant = async (url:string) => {
    try {
      const response = await axios.post('/api/authorizeMerchant', {});
      console.log('Merchant validation response: ', response);
      return response.data;
    } catch (error) {
      console.error('Merchant validation failed:', error);
      throw new Error('Merchant validation failed');
    }
  };

  const onApplePayButtonClicked = async () => {

    console.log('onApplePayButtonClicked ');
    if (!appleActivated) {
      return;
    }

    // Define ApplePayPaymentRequest
    const request = {
      countryCode: "DE",
      currencyCode: "EUR",
      merchantIdentifier: "merchant.rocks.point",
      merchantCapabilities: [
          "supports3DS",
          "supportsCredit",
          "supportsDebit"
      ],
      supportedNetworks: [
          "visa",
          "masterCard",
          "amex",
          "discover"
      ],
      requiredShippingContactFields: [
        "postalAddress",
        "name",
        "phone",
        "email"
      ],
      total: {
          "label": "Book appointment for free",
          "type": "final",
          "amount": "0.99"
      }
    };
  
    // Create ApplePaySession
    const session = new (window as any).ApplePaySession(3, request);

    session.onvalidatemerchant = async (event:any) => {
      // Call your own server to request a new merchant session.
      const merchantSession = await validateMerchant(event.validationURL);
      session.completeMerchantValidation(merchantSession);
      console.log("onvalidatemerchant", event, merchantSession);
    };
  
    session.onpaymentmethodselected = (event:any) => {
        // Define ApplePayPaymentMethodUpdate based on the selected payment method.
        // No updates or errors are needed, pass an empty object.
        const update = {};
        session.completePaymentMethodSelection(update);
        console.log("onpaymentmethodselected", event, update);
    };
  
    session.onshippingmethodselected = (event:any) => {
        // Define ApplePayShippingMethodUpdate based on the selected shipping method.
        // No updates or errors are needed, pass an empty object. 
        const update = {};
        session.completeShippingMethodSelection(update);
        console.log("onshippingmethodselected", event, update);
    };
  
    session.onshippingcontactselected = (event:any) => {
        // Define ApplePayShippingContactUpdate based on the selected shipping contact.
        const update = {};
        session.completeShippingContactSelection(update);
        console.log("onshippingcontactselected", event, update);
    };
  
    session.onpaymentauthorized = (event:any) => {

        console.log("onpaymentauthorized", event);
        // Define ApplePayPaymentAuthorizationResult
        const result = {
            "status": (window as any).ApplePaySession.STATUS_SUCCESS
        };
        session.completePayment(result);
        console.log("onpaymentauthorized", event, result);
    };
  
    session.oncancel = (event:any) => {
        // Payment canceled by WebKit
        console.log("oncancel", event);
    };
  
    session.begin();

  };

  React.useEffect(() => {
    loadScript("https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js", "apple-pay-api-script").then(
      async () => {
        const applePaySession: ApplePaySession = window.ApplePaySession;
        if (!applePaySession) {
          console.log("This device does not support Apple Pay");
          showSnackbar("This device does not support Apple Pay");
          setAppleActivated(false);
          return;
        }
        if (!applePaySession.canMakePayments()) {
          console.log("This device is not capable of making Apple Pay payments");
          showSnackbar("This device is not capable of making Apple Pay payments");
          setAppleActivated(false);
          return;
        }

        setAppleActivated(true);
        // console.log("appleActivated ", appleActivated);
        showSnackbar("Apple Pay activated");
      }
    );

    const applbtn = document.getElementById('apple-pay-button');
    applbtn?.addEventListener('click', onApplePayButtonClicked);

  }, [i18n, appleActivated, setAppleActivated, showSnackbar]);

  return (
    <div id="apple-pay-container" style={{ width: '100%', maxWidth: '240px' }} className="apple-pay-button" >
      {appleActivated && 
        <apple-pay-button 
          id="apple-pay-button" 
          buttonstyle="black" 
          type="book" 
          locale={i18n.resolvedLanguage} >
        </apple-pay-button>
      }

      <Box sx={{ width: 500 }}>
        <Snackbar
        anchorOrigin={{ vertical, horizontal  }}
        open={open}
        onClose={handleClose}
        message="I love snacks"
        key={'bottomCenter'}
        />
      </Box>


    </div>
  );
};

export default AppleCheckoutComponent;
