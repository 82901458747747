import React, { useContext } from 'react';
import PlacesSearchBar from '../Components/PlacesSearchBar';
import AppleCheckoutComponent from '../Components/AppleCheckoutComponent';
import GoogleCheckoutComponent from '../Components/GoogleCheckoutComponent';
import AppointmentTime from '../Components/AppointmentTime';
import { Box, Container, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { PlaceContext } from '../Context/PlaceContext';

const Home: React.FC = () => {

  const { t } = useTranslation();
  const { selectedPlace } = useContext(PlaceContext);

  const { query = '', city = '', type = '' } = useParams<{ query?: string; city?: string; type?: string }>();

  return (
    <div className="content">
      <Helmet>
        <title>Search Results for {query} in {city}</title>
        <meta name="description" content={`Search results for ${query} in ${city} of type ${type}`} />
        <meta name="keywords" content={`search, ${query}, ${city}, ${type}`} />
      </Helmet>
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <p className='home-title'>
            {t("Book Appointment")}
          </p>
        <PlacesSearchBar/>
        <Fade in={selectedPlace != null} timeout={800} >
          <div>
            <AppointmentTime />
            <AppleCheckoutComponent/>
            <GoogleCheckoutComponent/>
          </div>
        </Fade>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
