import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BottomNav from './Components/BottomNav';
import FlagSelector from './Components/FlagSelector';
import Home from './Pages/Home';
import Info from './Pages/Info';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Imprint from './Pages/Imprint';
import './i18n';
import './index.css';
import { DateTimeProvider } from './Context/DateTimeContext';
import { PlaceProvider } from './Context/PlaceContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const App: React.FC = () => {

  return (
    <Router>
        <ThemeProvider theme={darkTheme}>
        <DateTimeProvider >
        <PlaceProvider >
        <div>
        <FlagSelector />
        <div className="content">
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/(:query)(::city)(::type)" element={<Home />} />
            <Route path="/s/:query" element={<Home />} />
            <Route path="/info" element={<Info />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/imprint" element={<Imprint />} />
          </Routes>
        </div>
        <BottomNav />
      </div>
      </PlaceProvider >
      </DateTimeProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
