import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

// const Info: React.FC = () => {
//   const { t } = useTranslation();
//   return <div>{t('info')}</div>;
// };


// interface Props extends RouteComponentProps {}

const Info: React.FC = () => {
// class Info extends React.Component<Props> {

  const { t } = useTranslation();

  const handleNavigation = (path: string) => {
  };

    return (
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Link to="/terms">
          <Button
            variant="contained"
            sx={{ mb: 2, borderRadius: '20px', minWidth: '240px' }}
            onClick={() => handleNavigation('/terms')}
          >
          {t('Terms')}
          </Button>
          </Link>
          <Link to="/privacy">
          <Button
            variant="contained"
            sx={{ mb: 2, borderRadius: '20px', minWidth: '240px' }}
            onClick={() => handleNavigation('/privacy')}
          >
          {t('Privacy')}
          </Button>
          </Link>
          <Link to="/imprint">
          <Button
            variant="contained"
            sx={{ borderRadius: '20px', minWidth: '240px' }}
            onClick={() => handleNavigation('/imprint')}
          >
          {t('Imprint')}
          </Button>
          </Link>
        </Box>
      </Container>
    );
}

export default Info;
