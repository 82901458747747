import React, { useContext } from 'react';
import { getGeocode } from 'use-places-autocomplete';
import { TextField, IconButton, Typography, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import '../index.css';
import { useTranslation } from 'react-i18next';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import i18n from '../i18n';
import { PlaceContext, PlaceType } from '../Context/PlaceContext';
import loadScript from '../Tools/loadScript';

const placesService = { current: null };

// https://developers.google.com/maps/documentation/places/web-service/place-types#table-a


const PlacesSearchBar: React.FC = () => {

  const { t } = useTranslation();

  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const { selectedPlace, setSelectedPlace } = useContext(PlaceContext);

  const [loc, setLoc] = React.useState<google.maps.LatLng | null>(null);

  const loaded = React.useRef(false);

  const fetch = React.useMemo(
    () =>
      throttle(
        (request, callback: (results?: PlaceType[]) => void) => {

          request.componentRestrictions = {country: 'de'};
          request.language = i18n.resolvedLanguage;
          // request.types = ['doctor', 'dentist', 'restaurant'];
          const map = null;

          if (loc) {
            request.location = loc;
            request.radius = 1000;
          }

          // console.log("throttle: callback " + JSON.stringify(callback) + " request " + JSON.stringify(request));

          (placesService.current as any).textSearch(
            request,
            callback,
          );
        },
        200,
      ),
    [loc],
  );

  React.useEffect(() => {

    const URL = "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    loadScript(URL, "google-maps-api-script").then(
      async () => {
        loaded.current = true;
      }
    );

    let active = true;

    if (!placesService.current && (window as any).google) {
      placesService.current = new (window as any).google.maps.places.PlacesService( document.createElement('div') );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(selectedPlace ? [selectedPlace] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {

      // console.log("fetch: results " + JSON.stringify(results));

      if (active) {
        let newOptions = [] as PlaceType[];

        if (selectedPlace) {
          newOptions = [selectedPlace];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedPlace, inputValue, fetch]);

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const locs = new google.maps.LatLng({lat: latitude, lng: longitude});
          setLoc( locs );

          try {
            const results = await getGeocode({ location: { lat: latitude, lng: longitude } });
            const address = results[0].formatted_address;
            console.log("Location: " + JSON.stringify(position) + "address " + address + "loc " + loc + "locs " + locs);

          } catch (error) {
            console.error("Error: ", error);
          }
        },
        (error) => {
          console.error("Error: ", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="place-search-bar">
    <Autocomplete
      id="google-map-api-textfield"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.name
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      autoFocus
      forcePopupIcon={false}
      fullWidth
      includeInputInList
      onFocus={handleLocationClick}
      filterSelectedOptions
      value={selectedPlace}
      onChange={(event: any, newValue: PlaceType | null) => {

        // console.log("places change: event " + " newvalue " + JSON.stringify(newValue));

        setOptions(newValue ? [newValue, ...options] : options);

        if (newValue?.place_id) {
          setSelectedPlace(newValue);
        } else {
          setSelectedPlace(null);
        }

      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={t('Place')} 
        fullWidth 
        value={selectedPlace?.name}

        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <IconButton onClick={handleLocationClick}>
              <MyLocationIcon />
            </IconButton>
          ),
          style: { borderRadius: '24px' },
        }}
        />
      )}
      renderOption={(props, option) => {

        // console.log("renderOption: props " + JSON.stringify(props) + " option " + JSON.stringify(option));

        const matches = [option.name];
        const parts = parse(
          option.name,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems="center">
              <Grid>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  <span>{option.name}</span>
                  <span
                    style={{
                      fontSize: '10px',
                      width: '100%'
                    }}                  
                  >{"- " + option.formatted_address}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
    </div>
  );

};

export default PlacesSearchBar;
