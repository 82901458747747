import React from 'react';
import GooglePayButton from '@google-pay/button-react';
import { useTranslation } from 'react-i18next';

const GoogleCheckout: React.FC = () => {

  const { i18n } = useTranslation();

  return (
    <div style={{ width: '100%', maxWidth: '240px' }} className="google-pay-button">

      <GooglePayButton
      buttonLocale={i18n.resolvedLanguage}
         buttonType='book' 
         buttonRadius={10}
         buttonColor='default'

      existingPaymentMethodRequired={false}
      environment='TEST'
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                // gateway: "stripe",
                // "stripe:version": "2018-10-31",
                // "stripe:publishableKey": process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
              },
            },
          },
        ],
        emailRequired: true,
        shippingAddressRequired: true,
        shippingAddressParameters: { allowedCountryCodes: ['DE'], phoneNumberRequired: true },
        merchantInfo: {
          merchantId: process.env.GOOGLE_PAY_MERCHANT_ID as string,
          merchantName: process.env.GOOGLE_PAY_MERCHANT_NAME as string,          
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: '0.00',
          currencyCode: 'EUR',
          countryCode: 'DE',
        },
      }}
      onLoadPaymentData={paymentRequest => {
        console.log('load payment data', paymentRequest);
      }}

      onCancel={ (reason) => {
        console.log('Google Pay cancelled ' + reason);
      }}
      onError={ (error) => {
        console.error('Google Pay error ' + error);
      }}

    />

    </div>
  );
};

export default GoogleCheckout;
